<template>
    <div>
        <div align="center">
            <span class="text-h4">Acquisto del preventivo completato</span>
        </div>
        <br><br>
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-12 col-md-4">
                <table>
                    <tbody>
                        <tr>
                            <td align="right">Numero di pratica:</td>
                            <td class="risposta">{{NumeroPreventivo}}</td>
                        </tr>
                        <tr>
                            <td align="right">Data di creazione:</td>
                            <td class="risposta">{{DataCreazione}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12 col-md-4" align="justify">
                <br>
                La richiesta di acquisto della pratica &egrave; andata a buon fine. Sar&agrave;
                presa in carico al pi&ugrave; presto per emettere le polizze richieste.
                Consulta la sezione "PRATICHE" per seguire lo stato di avanzamento dei lavori.
            </div>
            <div class="col-md-2"></div>
        </div>
        <br><br><hr>

        <div class="col-12 col-md-6" align="center">
            <QQButton label="FINE" color="blue-grey" icon="mdi-check-outline" size="md"
                @click.native="onNuovoClicked"
            />
        </div>

    </div>
</template>

<script>
    import { mapState } from "vuex";
    import QQButton from "@/components/QQButton.vue";

    export default {
        name: "FineAcquisto",
        data() {
            return {

            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapState({
                formPreventivo: state => state.formPreventivo
            }),
            NumeroPreventivo() {
                return this.formPreventivo.preventivo.id;
            },
            DataCreazione() {
                return this.formPreventivo.preventivo.create_at;
            }
        },
        methods: {
            onNuovoClicked() {
                window.location = "/";
            }
        }
    }
</script>

<style scoped>
    td.risposta {
        border-bottom: 1px SOLID #808080;
        padding: 6px;
    }
</style>
